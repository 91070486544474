<template>
  <b-card body-class="p-0">
    <b-alert
      v-if="loading"
      show
      variant="warning"
      class="mt-1 px-2 py-1 d-flex-center gap-2 justify-content-start"
    >
      <b-spinner
        variant="warning"
        tag="span"
      />
      <span>
        {{ $t('loading') }}
      </span>
    </b-alert>
    <div v-else-if="isEmpty(serviceOptions)">
      <b-alert
        show
        variant="warning"
        class="mt-1"
      >
        <div class="alert-body text-center text-warning">
          <feather-icon
            class="mr-25"
            icon="FrownIcon"
            size="25"
          />
          <span>
            {{ $t('flight.noAncillary') }}
          </span>
        </div>
      </b-alert>
    </div>

    <b-card
      v-else
      class="mb-0 border-0"
      body-class="p-0"
    >
      <!-- NOTE: VN1A bỏ qua các hành trình có điểm dừng  https://discord.com/channels/1054696448110903327/1315896234463985694/1318860064261537793 -->
      <div v-if="!isDevelopment && selectedTrip.some(trip => ['VN1A'].includes(trip?.source) && trip?.segments?.length > 1)">
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body text-center text-warning">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
              size="25"
            />
            <span class="fw-700">
              Nếu có nhu cầu mua dịch vụ bổ sung cho chuyến bay có điểm dừng, vui lòng liên hệ booker hỗ trợ!
            </span>
          </div>
        </b-alert>
      </div>

      <b-row>
        <!-- ANCHOR - Chặng bay -->
        <b-col md="5">
          <b-form-group
            :label="$t('flight.flightSegment')"
            label-for="chang-bay"
            class="pb-25 mb-0"
          >
            <v-select
              v-model="segment"
              input-id="chang-bay"
              label="segmentData"
              class="select-size-sm mr-1 w-100"
              :clearable="false"
              :options="segmentOptions"
              :placeholder="$t('flight.placeholderSelect')"
            >
              <template #option="{ segmentData }">
                <div class="fw-700">
                  <span>
                    {{ $t('flight.priceReport.airline') }}:
                    {{ segmentData.source ? segmentData.source : segmentData.airline }} |
                  </span>
                  <span>
                    {{ segmentData ? $t('flight.segment'): $t('flight.itinerary') }}:
                    {{ ['TR', 'JQ'].includes(segmentData?.source) ? segmentData.itemFlight : `${segmentData.departure.IATACode}-${ segmentData.arrival.IATACode}` }}
                    ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </div>
              </template>

              <template #selected-option="{ segmentData }">
                <div class="font-small-4 fw-700">
                  <span>
                    {{ segmentData.source ? segmentData.source : segmentData.airline }}
                  </span>
                  |
                  <span>
                    {{ ['TR', 'JQ'].includes(segmentData?.source) ? segmentData.itemFlight : `${segmentData.departure.IATACode} - ${segmentData.arrival.IATACode}` }}
                    ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </div>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="my-50">

      <!-- ANCHOR - Passenger -->
      <div v-if="passengersData">
        <b-row
          v-for="(passenger, index) in passengersData"
          :key="index"
          class="w-100 m-0"
        >
          <b-col
            class="px-0 mt-1 mt-md-0 pb-25 pb-md-1 pt-25"
            md="4"
          >
            {{ $t('flight.passenger') }}:
            <span class="font-weight-bolder">
              {{
                passenger.firstName && passenger.lastName
                  ? `${passenger.title} ${passenger.lastName} ${passenger.firstName}`
                  : `${$t(`reservation.${passenger.paxType}`)} #${index + 1}`
              }}
            </span>
          </b-col>

          <!-- ANCHOR - Gói dịch vụ -->
          <b-col
            cols="12"
            md="5"
            class="px-0"
          >
            <v-select
              :id="`service-packages-${index}`"
              v-model="passenger.servicePackage"
              class="ssrs_package_select select-size-sm w-100"
              label="code"
              :filter-by="filterBy"
              :placeholder="$t('flight.servicePlaceholder')"
              :disabled="disableServicePackage"
              :options="serviceListOptions.filter(item => ['JQ'].includes(item?.source) ? !excludedBundleSsrJQ.includes(item.code) : true)"
              @input="(val) => setDefaultPassengerAmount(val, passenger)"
            >
              <template #option="data">
                <div :class="`d-flex justify-content-between ${isMobileView ? 'font-small-1' : 'font-small-4'}`">
                  <span :class="`mr-75 ${resolveVariantByBagg(data)}`">
                    {{
                      ['JQ'].includes(data?.source ?? data?.airline)
                        ? ((data.code && data.key) ? `${data.code} - ${data.key}` : (data.key ?? data.code))
                        : resolveSsrNameByCode(['TR'].includes(data?.airline) ? `${data.airline}_${data.code}` : data.code)
                    }}
                  </span>
                  <span class="font-weight-bolder text-warning">
                    {{ formatCurrency(resolvePriceService(passenger, data)) }}
                  </span>
                </div>
              </template>
              <template #selected-option="data">
                <div :class="`font-weight-bolder ${isMobileView ? 'font-small-1' : 'font-small-4'}`">
                  <span class="mr-50">
                    {{
                      ['JQ'].includes(data?.source ?? data?.airline)
                        ? ((data.code && data.key) ? `${data.code} - ${data.key}` : (data.key ?? data.code))
                        : resolveSsrNameByCode(['TR'].includes(data?.airline) ? `${data.airline}_${data.code}` : data.code)
                    }}
                  </span>
                  (<span class="text-warning"> {{ formatCurrency(resolvePriceService(passenger, data)) }} </span>)
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- ANCHOR - Số lượng mua -->
          <b-col
            cols="4"
            md="2"
            class="mt-50 mt-md-0 px-0 px-md-1"
          >
            <v-select
              :id="`service-packages-amount-${index}`"
              v-model="passenger.serviceAmount"
              class="select-size-sm w-100"
              label="text"
              :placeholder="$t('flight.Amount')"
              :clearable="false"
              :disabled="disableServicePackage || (segment && ['VN1A'].includes(segment.source)) || !passenger.servicePackage"
              :options="amountOptions"
            >
              <template #option="{ text }">
                <span class="font-small-3">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
              <template #selected-option="{ text }">
                <span class="font-small-3 pl-50">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="!isHideFunction"
        class="text-center mt-50"
      >
        <b-button
          class="mr-2"
          variant="outline-warning"
          @click="handleClearForm"
        >
          {{ $t('flight.clear') }}
        </b-button>

        <b-button
          variant="primary"
          @click="handleClickAddButton"
        >
          {{ $t('flight.addService') }}
        </b-button>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import {
  BSpinner, BRow, BCol, BCard, BAlert, BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed,
  toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'

import { resolveSsrBagsName, resolveSsrNameByCode, resolveVariantByBagg } from '@/constants/selectOptions'
import store from '@/store'
import env from '@/libs/env'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import useCreateBookingHandle from '@flightv2/reservation/useCreateBookingHandle'
import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BFormGroup,
    BSpinner,

    vSelect,
  },
  props: {
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  /*
  ----- CÁC HÃNG MUA DỊCH VỤ THÊM KHI TẠO BOOKING -----
  - MUA THEO CHẶNG:  QH, VN1A, VU, AK, F1, TH.
  - MUA THEO HÀNH TRÌNH:  VJ.
  - MUA THEO CẢ CHẶNG HOẶC CHẶNG/HÀNH TRÌNH: TR, JQ.
  */
  setup(props) {
    const { toastSuccess, toastWarning, toastError } = useToast()
    const { isEnableFnSourceAKJSON, isDevelopment } = env
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      getAddonsData,
    } = useBookingHandle()
    const {
      isHideFunction,
      excludedBundleSsrJQ,
      bundlesJQ,
    } = useCreateBookingHandle()
    const { isCombination } = toRefs(props)
    const passengersData = ref(null)

    function setBlankDataPassengers() {
      passengersData.value = cloneDeep(
        props.passengerDataToAdd.filter(
          passenger => passenger.paxType !== 'INFANT',
        ),
      )
    }

    // Set passenger data
    watch(() => props.passengerDataToAdd, () => {
      setBlankDataPassengers()
    }, { deep: true, immediate: true })

    const loading = computed(() => store.getters['app-create-booking/getIsRetryLoading'] || false)

    // SECTION  SERVICE ADDONS
    const serviceOptions = computed(() => store.getters['app-create-booking/getSsrOthers'] || [])
    const ssrBags = computed(() => store.getters['app-create-booking/getSsrBags'] || [])

    const bundleOptions = computed(() => {
      if (isEmpty(serviceOptions.value) || isEmpty(props.selectedTrip)) return []
      if (!props.selectedTrip.some(trip => ['JQ'].includes(trip?.source))) return []

      return serviceOptions.value.filter(item => excludedBundleSsrJQ.includes(item?.code))
    })

    function getSsrDataBySource(ssrOtherLists, tripOrSegment, segmentFlights) {
      if (['VN1A'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(item => item.segmentIds.map(item => item.split('-')[0]).includes(tripOrSegment.departure.IATACode) && (isCombination.value ? true : (item?.itineraryId === tripOrSegment.airlineItineraryId)))
        return result
      }

      if (['TH'].includes(tripOrSegment.source)) {
        return ssrOtherLists.filter(item => item.flights === segmentFlights && item.segmentIds.includes(tripOrSegment.segmentId ? tripOrSegment.segmentId : tripOrSegment.segments[0].segmentId))
      }

      if (['TR', 'JQ'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(bagItem => bagItem.flights === tripOrSegment.itemFlight)
        return result
      }

      if (['VJ'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(item => segmentFlights.includes(item.flights) && item.segmentIds[0] === tripOrSegment.segments[0].segmentId)
        return result
      }

      if (['VU'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(item => (item.flights === segmentFlights) && item.segmentIds.includes(tripOrSegment.segmentId ? tripOrSegment.segmentId : tripOrSegment.segments[0].segmentId))
        return result
      }

      return ssrOtherLists.filter(item => item.flights === segmentFlights)
    }

    const uniqueByFlights = array => {
      const map = new Map()
      return array.filter(item => {
        const key = JSON.stringify(item.flights)
        if (!map.has(key)) {
          map.set(key, true)
          return true
        }
        return false
      })
    }

    // SECTION SEGMENT
    const segment = ref(null)
    const segmentOptions = ref([])

    watch(() => serviceOptions.value, ssrOtherOptionValues => {
      segment.value = null
      segmentOptions.value = []
      setBlankDataPassengers()

      if (!isEmpty(ssrOtherOptionValues)) {
        const flightOrSegmentLists = []
        let airlineSegmentId = 1

        // NOTE: VN1A bỏ qua các hành trình có điểm dừng  https://discord.com/channels/1054696448110903327/1315896234463985694/1318860064261537793
        const trips = !isDevelopment && props.selectedTrip.some(trip => ['VN1A'].includes(trip?.source)) ? props.selectedTrip.filter(trip => trip?.segments?.length < 2) : props.selectedTrip

        trips.forEach((trip, tripIndex) => {
          if (['TR', 'JQ'].includes(trip.source)) { // TR, JQ thêm hành trình vào list, result cả chặng và hành trình
            const listSsrInTrip = ssrOtherOptionValues.filter(item => item.segmentIds.every(itemSegmentId => trip.segments.some(segment => segment.segmentId === itemSegmentId)))
            const listSsrUnique = uniqueByFlights(listSsrInTrip)
            listSsrUnique.forEach(item => {
              flightOrSegmentLists.push({ ...trip, itemFlight: item.flights, segments: trip.segments.filter(segment => item.segmentIds.includes(segment.segmentId)) })
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            if (['VN1A', 'AK', 'TH'].includes(trip.source)) {
              trip.segments.forEach(item => {
                flightOrSegmentLists.push({
                  ...item,
                  tripFlight: `${trip.departure.IATACode}-${trip.arrival.IATACode}`,
                  ...(isCombination.value && {
                    tripId: tripIndex + 1,
                    airlineSegmentId: String(airlineSegmentId),
                  }),
                  itineraryId: trip.itineraryId ? trip.itineraryId.toString() : null,
                })

                if (isCombination.value) {
                  airlineSegmentId += 1
                }
              })
            } else {
              flightOrSegmentLists.push(trip) // VJ
            }
          }
        })

        flightOrSegmentLists.forEach((dataFlight, index) => {
          const flightsName = ['TR', 'JQ'].includes(dataFlight?.source ?? '')
            ? dataFlight.segments.map(s => `${s.departure.IATACode}-${s.arrival.IATACode}`).join(' | ')
            : `${dataFlight.departure.IATACode}-${dataFlight.arrival.IATACode}`

          const mappedAddonsData = {
            segmentData: {
              ...dataFlight,
              segmentIndex: index + 1,
              flights: flightsName,
            },
            ssrData: getSsrDataBySource(ssrOtherOptionValues, dataFlight, flightsName),
          }

          if (mappedAddonsData.ssrData.length > 0) {
            segmentOptions.value.push(mappedAddonsData)
          }
        })
      }
    }, { deep: true, immediate: true })

    // SECTION ADDONS OPTION
    const serviceListOptions = ref([])
    const amountOptions = ref([])

    watch(() => segment.value, segmentValue => {
      amountOptions.value = []
      serviceListOptions.value = []
      setBlankDataPassengers()

      if (segmentValue) {
        const data = segmentValue.ssrData.map(item => ({
          segmentData: segmentValue.segmentData,
          ...item,
        }))

        if (!isEmpty(data)) {
          serviceListOptions.value = data.filter(item => !isEmpty(item.fares) && (item.fares[0].total >= 0) /* Hiển thị cả SSR 0đ cho AK */)
          serviceListOptions.value = serviceListOptions.value.filter(item => !['UMNR', 'INFT'].includes(item.code))
        }

        // NOTE: mua nhiều gói chỉ áp dụng baggage VN1A, QH
        // if (data[0]?.segmentData?.source?.includes('VN1A') && !isEmpty(data) && data[0].ssrName === 'ssrBags') {
        //   // const option = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        //   const option = [1] // FIXME : VNA 1 gói / hành trình
        //   amountOptions.value.push(...option)
        // } else {
        amountOptions.value.push(1)
        // }
      }
    }, { deep: true })

    function resolvePriceService(pax, ssr) {
      const fare = ssr?.fares?.find(fare => fare?.paxType === pax?.paxType) || ssr?.fares?.[0]
      return fare?.total || 0
    }

    // handle set setDefaultPassengerAmount
    const setDefaultPassengerAmount = (val, passenger) => {
      passenger.serviceAmount = val !== null ? 1 : null
    }

    // handle clear form
    function handleClearForm() {
      segment.value = null
      // serviceSelected.value = null
      setBlankDataPassengers()
    }

    // ANCHOR - HANDLE ADD-ONS --- Add service
    function addDataToStore(ssrsToAdd) {
      for (const ssrData of ssrsToAdd) {
        const rawData = cloneDeep(getAddonsData.value)
        rawData.push(ssrData)

        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAddonsSelectData`, rawData)
        handleClearForm()
      }
      toastSuccess({ title: 'Thêm dịch vụ thành công!' })
    }

    // SECTION DISABLE FORM
    const disableServicePackage = computed(() => !segment.value)

    function getDataSSR(pax, ssr) {
      const data = {
        // ========== Data Create Booking ==========
        code: ssr.code,
        type: '',
        name: '',
        ...(ssr.details && {
          details: ssr.details.map(details => ({
            weight: String(details.weight),
            unit: details.unit,
          })),
        }),
        ...(['VJ', 'VZ', 'VN'].includes(ssr.airline) && { itineraryId: ssr.segmentData.airlineItineraryId }),
        ...(['TR', 'JQ'].includes(ssr.source) && {
          itineraryId: ssr.itineraryId ?? '',
          segmentIds: ssr.segmentIds ?? [],
        }),
        ...(['TH', 'AK'].includes(ssr.source ?? ssr.airline) && { itineraryId: ssr.segmentData?.itineraryId || null }),
        ...(['VU'].includes(ssr.airline) && { itineraryId: ssr.segmentData.segments[0].segmentValue }),
        segmentId: ['VN'].includes(ssr.airline)
          ? String(ssr?.segmentData?.airlineSegmentId)
          : ['TR', 'JQ'].includes(ssr.source ?? ssr.airline)
            ? ssr?.segmentIds?.join(' | ')
            : isEmpty(ssr.segmentIds)
              ? ''
              : ssr.segmentIds[0], // NOTE segmentIds từ api list-ancillary
        paxId: pax.paxId,
        addonValue: (['VJ', 'VZ', 'VN', 'TR', 'JQ'].includes(ssr.source ?? ssr.airline) || (['AK'].includes(ssr.source ?? ssr.airline) && isEnableFnSourceAKJSON))
          ? ssr.ssrValue
          : resolvePriceService(pax, ssr),

        // ========== Others ==========
        paxType: pax.paxType,
        ssrName: ssr.ssrName, // toCheck
        ssrId: ssr.ssrId, // toCheck

        // ========== Data Show Price ==========
        // HÀNH KHÁCH
        firstName: pax.firstName,
        lastName: pax.lastName,

        // CHẶNG BAY
        flights: (ssr.airline === 'QH' && ssr.ssrName === 'ssrBags')
          ? ssr.segmentData.flights
          : ssr.flights,

        tripId: ssr.segmentData?.tripId ?? ssr.segmentData?.segments?.[0]?.tripId ?? '', // check to add segmentId follow segmentValue[0]

        // HÃNG HÀNG KHÔNG
        airline: ssr.airline,

        // GIÁ
        addonPrice: resolvePriceService(pax, ssr),
        amount: pax.serviceAmount,

        // DỊCH VỤ
        serviceName: ['JQ'].includes(ssr.source)
          ? (ssr.ssrName === 'ssrBags' ? (ssr.details ? resolveSsrBagsName(ssr) : resolveSsrNameByCode(ssr.code)) : ssr.key)
          : ssr.details
            ? resolveSsrBagsName(ssr)
            : resolveSsrNameByCode(['TR'].includes(ssr.airline) ? `${ssr.airline}_${ssr.code}` : ssr.code),

        source: ssr.source,
      }

      return data
    }

    function handleClickAddButton() {
      const checkArrayItems = passengersData.value.every(item => item.servicePackage === null && item.serviceAmount === null)

      if (!segment.value || checkArrayItems) {
        toastWarning({ title: 'Vui lòng chọn gói dịch vụ!' })
        return
      }

      const dataSSR = passengersData.value.filter(p => p.servicePackage).map(pax => getDataSSR(pax, pax.servicePackage))

      // JQ: update thêm các gói nếu chọn bundle
      if (props.selectedTrip.some(trip => ['JQ'].includes(trip.source))) {
        const ssrBundleSelectList = dataSSR.filter(ssr => bundlesJQ.some(it => it.bundleCode === ssr.code))
        if (!isEmpty(ssrBundleSelectList)) {
          // tìm các gói theo ssrBundle => thêm cả list vào cho pax
          ssrBundleSelectList.forEach(ssrBundle => {
            const getListBundles = bundlesJQ.find(it => it.bundleCode === ssrBundle.code)?.bundles
            if (isEmpty(getListBundles)) return

            const bundleUid = uuidv4()
            const updateList = []

            const indexSsrBundle = dataSSR.findIndex(item => item.paxId === ssrBundle.paxId)
            if (indexSsrBundle !== -1) {
              dataSSR[indexSsrBundle].bundleUid = bundleUid
            }

            getListBundles.forEach(bundleItem => {
              const includeSsr = bundleOptions.value.find(item => (item.code === bundleItem.code) && ssrBundle.segmentIds.some(seg => item.segmentIds.includes(seg)))
              const pax = passengersData.value.find(pax => pax.paxId === ssrBundle.paxId)

              if (pax && bundleItem.type === 'SSR_BAGGAGE') {
                const ssrBaggageByBundleCode = ssrBags.value?.find(bagItem => bagItem.code === bundleItem.code && ssrBundle.segmentIds.every(seg => bagItem.segmentIds.includes(seg)))
                const bagBundleItem = getDataSSR(pax, ssrBaggageByBundleCode)
                if (bagBundleItem.addonPrice <= 0) {
                  updateList.push({
                    ...bagBundleItem,
                    tripId: ssrBundle.tripId,
                    bundleUid,
                    // addonPrice: 0,
                  })
                }
              }

              if (pax && includeSsr) {
                const ssrOtherBundleItem = getDataSSR(pax, includeSsr)
                if (ssrOtherBundleItem.addonPrice <= 0) {
                  updateList.push({
                    ...ssrOtherBundleItem,
                    tripId: ssrBundle.tripId,
                    bundleUid,
                    // addonPrice: 0,
                  })
                }
              }
            })
            dataSSR.push(...updateList)
          })
        }
      }

      const existingPaxSsrs = dataSSR.filter(paxSsr => {
        let existingPax
        let errTitle
        if (['TR'].includes(paxSsr.source ?? paxSsr.airline)) {
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.ssrId === paxSsr.ssrId)
          if (existingPax) {
            errTitle = `Hành khách ${paxSsr.firstName ? `${paxSsr.lastName} ${paxSsr.firstName} ` : `${paxSsr.paxType} ${paxSsr.paxId}`} đã có gói dịch vụ ở chặng này, không thể mua thêm!`
          }
        } else if (['JQ'].includes(paxSsr.source ?? paxSsr.airline)) {
          const passengerName = paxSsr.firstName
            ? `${paxSsr.lastName} ${paxSsr.firstName}`
            : `${paxSsr.paxType} ${paxSsr.paxId}`

          // Nếu pax đã thêm gói bundle => ko cho thêm gói bundle khác (trên hành trình)
          const isBundle = bundlesJQ.some(it => paxSsr.code === it.bundleCode)
          let existUpdateBundle = null
          if (isBundle) {
            existUpdateBundle = getAddonsData.value.find(ssr => {
              const isSamePax = ssr.paxId === paxSsr.paxId
              const isSameFlight = ssr.flights === paxSsr.flights
              const isSameItinerary = ssr.itineraryId === paxSsr.itineraryId
              const isAddedBundles = bundlesJQ.some(it => ssr.code === it.bundleCode)
              return isSamePax && isSameFlight && isSameItinerary && isBundle && isAddedBundles
            })

            if (existUpdateBundle) {
              existingPax = existUpdateBundle
              errTitle = `Hành khách ${passengerName} đã thêm GÓI NÂNG CẤP ở hành trình này, không thể mua thêm!`
            }
          }

          if (!existUpdateBundle) {
            existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.ssrId === paxSsr.ssrId && !ssr.bundleUid)
            if (existingPax) {
              errTitle = `Hành khách ${passengerName} đã có gói dịch vụ ở chặng này, không thể mua thêm!`
            }
          }
        } else {
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.ssrId === paxSsr.ssrId)
        }

        if (existingPax) {
          const data = existingPax
          if (data.airline === 'AK') {
            data.code = data.serviceName
          }

          const addOnlyOneSsrText = `${data.firstName ? `${data.lastName} ${data.firstName} ` : `${data.paxType} ${data.paxId} `} chỉ được mua một gói ${resolveSsrNameByCode(data.code) || data.code} trên một chặng/hành trình.`
          toastError({
            title: ['TR', 'JQ'].includes(paxSsr.source ?? paxSsr.airline) ? errTitle : addOnlyOneSsrText,
          })
          return data
        }

        return existingPax
      })

      if (existingPaxSsrs.length === 0) {
        addDataToStore(dataSSR)
      }
    }

    function filterBy(option, label, search) {
      const code = (option?.code ?? '').toUpperCase()
      const searchUpper = (search ?? '').toUpperCase()
      const detailsUpper = (`${option?.details[0]?.weight}` ?? '').toUpperCase()
      return code?.includes(searchUpper) || detailsUpper.includes(searchUpper)
    }

    return {
      handleClickAddButton,
      handleClearForm,
      setDefaultPassengerAmount,
      resolveSsrNameByCode,
      resolveSsrBagsName,
      resolvePriceService,

      serviceOptions,
      segmentOptions,
      serviceListOptions,
      amountOptions,
      passengersData,
      segment,

      disableServicePackage,
      formatCurrency,

      isEmpty,
      resolveVariantByBagg,
      convertISODateTime,
      filterBy,
      loading,
      isHideFunction,
      isDevelopment,

      excludedBundleSsrJQ,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.ssrs_package_select {
  ::v-deep .vs__dropdown-option {
    text-wrap: wrap !important;
  }

  ::v-deep .vs__dropdown-option--selected {
    color: black !important;

    &.vs__dropdown-option--highlight {
      color: black !important;
    }
  }
}
</style>
